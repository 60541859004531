import React, { useEffect, useState, useContext } from "react";
import authInstance from "./firebase";
import mapIcon from "./images/map_icon.svg";
import $ from "jquery";
import "./Map.css";
import axios from "axios";
import { notification, Button, Modal } from "antd";
import anime from "animejs";
import UserContext from "./User/UserContext";
import CoordsContext from "./Coords/Coords";
import { app } from "firebase";
import withGlobalCoords from "./Coords/Coords";
// import { Helmet } from "react-helmet";

const Map = (props) => {
  const [locations, setLocations] = useState([]);
  const [numLocations, setNumLocations] = useState(0);
  const [userID, setUserID] = useState("");
  const [user, setUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const appUser = useContext(UserContext);
  const globalCoords = useContext(CoordsContext);

  const DDtoDMS = (lat, long) => {
    let latDeg = Math.floor(lat * -1);

    let longDeg = Math.floor(long);

    let latMin = Math.floor(
      (lat * -1 - latDeg) * 60
    );
    let longMin = Math.floor((long - longDeg) * 60);

    let latSec =
      ((lat * -1 - latDeg) * 60 - latMin) * 60;
    let longSec =
      ((long - longDeg) * 60 - longMin) * 60;

    return { latDeg, latMin, latSec, longDeg, longMin, longSec };
  }

  useEffect(() => {
    if (props.dropPinClicked) {
      console.log('Drop pin command received');
      $('#drop-pin-btn').trigger('click');
      props.setDropPinClicked(false);
    }
  }, [props.dropPinClicked]);

  const pinBtnClick = () => {
    setVisible(true);
  }

  function getLocations(fn) {
    axios
      .get("https://mysterious-citadel-66074.herokuapp.com/locations")
      // .get("http://localhost:5000/locations")
      .then((res) => {
        // console.log("fetched locations:");
        // console.log(res.data);
        setLocations(res.data);
        setNumLocations(res.data.length);
        // fn();
      });
  }

  function handleOk() {
    setVisible(false);
  }

  function handleCancel() {
    setVisible(false);
  }

  const close = () => {
    console.log(
      "Notification was closed. Either the close button was clicked or duration time elapsed."
    );
  };

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => props.setLogModalVisible(true)}
      >
        Confirm
      </Button>
    );
    notification.open({
      message: "Position updated",
      description:
        "You position has been updated. Click the button below to log a destination at your chosen location!",
      btn,
      key,
      onClose: close,
    });
  };

  function initMap(userData) {
    let now = new Date();

    let mapStyles = [];
    let endMarker;

    if (now.getHours() > 17 || now.getHours() < 6) {
      // if (now.getHours() < 0) {
      mapStyles = [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#1d2c4d",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#8ec3b9",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1a3646",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#4b6878",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#64779e",
            },
          ],
        },
        {
          featureType: "administrative.province",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#4b6878",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#334e87",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [
            {
              color: "#023e58",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#283d6a",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#6f9ba5",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1d2c4d",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#023e58",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3C7680",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#304a7d",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#98a5be",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1d2c4d",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#2c6675",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a7c235",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#255763",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#b0d5ce",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#023e58",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#98a5be",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#1d2c4d",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#283d6a",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#3a4762",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#0e1626",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#4e6d70",
            },
          ],
        },
      ];
    } else {
      mapStyles = [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a7c235",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ];
    }

    ////////////////////////////////

    function dropPin() {
      // if any previous marker exists, let's first remove it from the map
      if (endMarker) {
        endMarker.setMap(null);
      }

      let myLatLng = {};

      navigator.geolocation.getCurrentPosition(
        (position) => {
          //position.coords.latitude
          myLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          // console.log(myLatLng);

          endMarker = new window.google.maps.Marker({
            position: myLatLng,
            animation: window.google.maps.Animation.DROP,
            map: map,
            draggable: true,
          });

          props.setPinCoords([
            myLatLng.lat,
            myLatLng.lng,
          ]);

          // globalCoords.setGlobalCoords(DDtoDMS(myLatLng.lat, myLatLng.lng));
          // console.log('Global Coords ', globalCoords);

          window.google.maps.event.addListener(endMarker, "dragend", function () {
            // copyMarkerpositionToInput();
            // alert("Test");
            // props.setPinCoords(
            //   endMarker.getPosition().lat() + "," + endMarker.getPosition().lng()
            // );

            // globalCoords.setGlobalCoords(DDtoDMS(endMarker.getPosition().lat(), endMarker.getPosition().lng()));

            props.setPinCoords([
              endMarker.getPosition().lat(),
              endMarker.getPosition().lng(),
            ]);

            console.log(
              endMarker.getPosition().lat() + "," + endMarker.getPosition().lng()
            );

            openNotification();
            // notification["success"]({
            //   message: "Location Updated",
            //   description:
            //     "Your location has been updated. Log a new location to see the change!",

            //   duration: 8,
            // });
          });

        },
        () => {
          alert("Unable to retrieve your current location. Please ensure that your location services are turned on.");
        }
      );



      // create the marker
      // endMarker = new window.google.maps.Marker({
      //   position: map.center,
      //   animation: window.google.maps.Animation.DROP,
      //   map: map,
      //   draggable: true,
      // });



      // copyMarkerpositionToInput();
      // add an event "onDrag"
      // window.google.maps.event.addListener(endMarker, "dragend", function () {
      //   // copyMarkerpositionToInput();
      //   // alert("Test");
      //   // props.setPinCoords(
      //   //   endMarker.getPosition().lat() + "," + endMarker.getPosition().lng()
      //   // );
      //   props.setPinCoords([
      //     endMarker.getPosition().lat(),
      //     endMarker.getPosition().lng(),
      //   ]);

      //   console.log(
      //     endMarker.getPosition().lat() + "," + endMarker.getPosition().lng()
      //   );

      //   openNotification();
      //   // notification["success"]({
      //   //   message: "Location Updated",
      //   //   description:
      //   //     "Your location has been updated. Log a new location to see the change!",

      //   //   duration: 8,
      //   // });
      // });
    }


    ////////////////////////////////

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: -26.1952, lng: 28.034088 },
      zoom: 6,
      styles: mapStyles,
    });

    $("#drop-pin-btn").on("click", dropPin);

    let icon = "./map_icon.png";

    // let icon = { mapIcon };

    // let marker = new window.google.maps.Marker({
    //     position: { lat: -26.19219, lng: 28.03732 },
    //     animation: window.google.maps.Animation.DROP,
    //     map,
    //     icon: icon,
    // });

    let attrs = [
      "High ground clearance required",
      "4x4 only",
      "Available 24/7",
      "Entry fee applicable",
      "Bookings essential",
      "Wheelchair friendly",
      "Accessible by motorcycle only",
      "Accessible by boat only",
      "Child friendly",
      "Pets allowed",
      "Historic site",
      "Sunset Destination",
      "Sunrise Destination",
    ];

    const infowindow = new window.google.maps.InfoWindow();

    let loggedUser = authInstance.auth().currentUser;
    // console;
    // console.log(`LOGGED IN USER ID: ${loggedUserID}`);

    locations.map((item, index) => {
      // console.log("Creating new marker");
      // console.log(parseFloat(item.lat));
      // console.log(parseFloat(item.long));

      let coordinates = item.coordinates;
      coordinates = coordinates.replace(/"/g, "%22");
      coordinates = coordinates.replace(/'/g, "%27");

      let longDeg = parseFloat(item.long[0]);
      let longMin = parseFloat(item.long[1]);
      let longSec = parseFloat(item.long[2]);

      let long = longDeg + longMin / 60 + longSec / 3600;

      let attrMarkup = "";

      // for (let i = 0; i < item.attributes.length; i++) {
      //     switch (item.attributes[i]) {
      //         case 0:
      //     }
      // }

      let currentAttrs = [];

      attrs.map((attr, index) => {
        if (item.attributes[index]) {
          currentAttrs.push(attr);
        }
      });

      // console.log(currentAttrs);

      currentAttrs.map((attr) => {
        attrMarkup += `<div class="attribute"><i class="fal fa-check-circle"></i> ${attr}</div>`;
      });

      let btnMarkup = "";
      let loggedByString = "";

      item.logged_by ? loggedByString = `<p><span class="logged_by">Logged by: </span>${item.logged_by}</p>` : loggedByString = "";
      // if (item.logged_by)

      // if (props.isLoggedIn) {
      if (userData) {
        // console.log('AppUser: ', userData);
        // console.log("Rendering loggedIn btn");
        btnMarkup = `<button id=${coordinates} onclick='logVote("${item.name}", "${userData.uid}");' class="vote-now"><i class="fas fa-heart"></i></button>`;
      } else {
        console.log('No USER');
        btnMarkup = `<button class="vote-now grayed-out"><i class="fas fa-heart"></i></button>`;
      }

      //Map popup
      const contentString =
        `<div class="info-window-content">` +
        `<div id="siteNotice">` +
        `</div>` +
        `<p><span class="vehicle">Vehicle: </span>${item.vehicle}</p>` +
        `<h1 class="info-window-heading">${item.name}</h1>` +
        `<div class="dest-img"><img src=${item.image} /></div>` +
        loggedByString +
        `<p>${item.description}</p>` +
        `<div class="att-wrap"> ` +
        attrMarkup +
        `</div>` +
        `<div class="info-window-content">` +
        `<a class="go-now" src="https://www.google.com/maps/place/${coordinates}" onclick="window.open('https://www.google.com/maps/place/${coordinates}');" class="go-now-btn"><i class="fad fa-map-marker-alt"></i> Go Now</a>` +
        btnMarkup +
        `</div>` +
        `</div>`;

      // const infowindow = new window.google.maps.InfoWindow({
      //   content: contentString,
      // });

      let marker = new window.google.maps.Marker({
        position: { lat: parseFloat(item.lat), lng: parseFloat(long) },
        animation: window.google.maps.Animation.DROP,
        map,
        icon: icon,
      });

      // marker.addListener("click", () => {
      //   infowindow.open(map, marker);
      // });

      window.google.maps.event.addListener(
        marker,
        "click",
        (function (marker) {
          return function () {
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
          };
        })(marker)
      );
    });
  }

  function test() {
    alert("Test");
  }

  // useEffect(() => {
  //   // authInstance.auth().onAuthStateChanged(function (user) {
  //   //   console.log("User useEffect called");
  //   //   if (user) {
  //   //     setUser(user);
  //   //   } else {
  //   //     setUser(null);
  //   //   }
  //   // });
  // }, [user]);

  useEffect(() => {
    // console.log('GlobalCoords ', globalCoords);
    // getLocations(initMap);

    // authInstance.auth().onAuthStateChanged(function (user) {
    //   console.log("User useEffect called");
    //   if (user) {
    //     setUser(user);
    //   } else {
    //     setUser(null);
    //   }
    // });

    authInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        if (locations.length === 0) {
          getLocations();
        }
        if (locations.length > 1) {
          initMap(user);
        }
      } else {
        if (locations.length === 0) {
          getLocations();
        }
        if (locations.length > 1) {
          initMap(null);
        }
      }
    });

    // setTimeout(addListeners, 3000);

    // initMap();
    // if (locations.length === 0) {
    //   getLocations();
    // }
    // if (locations.length > 1) {
    //   initMap();
    // }
  }, [numLocations]);

  return (
    <div>
      <button id="drop-pin-btn" onClick={pinBtnClick} style={{ display: 'none' }}>
        <i class="fas fa-map-pin fa-lg"></i>
      </button>
      <Modal
        title="Drop a pin"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >

        <p><strong>A pin has been dropped at your location. Open the 'Log your own' window to use your location to log a new destination.</strong></p>
        <p>Feel free to move the pin as necessary to help us get the most accurate location!</p>
      </Modal>
      <div id="map"></div>
    </div>
  );
};

export default Map;

import React, { useEffect, useState } from "react";
import authInstance from "./firebase";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Axios from "axios";
import { message } from "antd";

const LoginSignupModal = (props) => {
  const [regName, setRegName] = useState("");
  const [regPass, setRegPass] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [loginEmail, setLoginEmail] = useState("");

  function updateRegEmail(e) {
    setRegEmail(e.target.value);
  }

  function updateRegName(e) {
    setRegName(e.target.value);
  }

  function updateRegPass(e) {
    setRegPass(e.target.value);
  }

  function updateLoginEmail(e) {
    setLoginEmail(e.target.value);
  }

  function updateLoginPass(e) {
    setLoginPass(e.target.value);
  }

  function registerUser(e) {
    e.preventDefault();
    authInstance
      .auth()
      .createUserWithEmailAndPassword(regEmail, regPass)
      .then((registeredUser) => {
        console.log(`NEW USER CREATED: ${registeredUser.user.uid}`);

        Axios.post("https://mysterious-citadel-66074.herokuapp.com/newuser", {
          uid: registeredUser.user.uid,
          votes: [],
        })
          .then()
          .catch((err) => {
            console.log(err);
          });

        var user = firebase.auth().currentUser;

        user
          .updateProfile({
            displayName: regName,
            photoURL: "/images/avatar.svg",
          })
          .then(function () {
            console.log("User profile set:");
            console.log(`Username: ${regName}`);
          })
          .catch(function (error) {
            console.log("Error updating user profile");
          });

        console.log("User created");
        props.setDisplayLogin(false);
        window.location.reload();
        props.displayMessage(`Welcome, ${regName}!`);
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == "auth/weak-password") {
          message.error("Password too weak.");
        } else {
          message.error(errorMessage);
        }
        console.log(error);
      });
  }

  function loginUser(e) {
    e.preventDefault();
    authInstance
      .auth()
      .signInWithEmailAndPassword(loginEmail, loginPass)
      .then(() => {
        console.log("Successfully signed in");
        props.displayMessage(`Welcome!`);
        props.setDisplayLogin(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log("Error signing in");
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
        props.displayMessage(errorMessage);
      });
  }

  function closeModal() {
    props.setDisplayLogin(false);
  }

  useEffect(() => {
    // var firebaseConfig = {
    //     apiKey: "AIzaSyDJkkPhVnMrJGLj1N1q7DKl6X0aN3RGN80",
    //     authDomain: "suzuki-go100.firebaseapp.com",
    //     databaseURL: "https://suzuki-go100.firebaseio.com",
    //     projectId: "suzuki-go100",
    //     storageBucket: "suzuki-go100.appspot.com",
    //     messagingSenderId: "484739901475",
    //     appId: "1:484739901475:web:677e42748f4b1ec263a996",
    //     measurementId: "G-YBPRC7YXNL",
    // };
    // firebase.initializeApp(firebaseConfig);

    const signupButton = document.getElementById("signup-button"),
      loginButton = document.getElementById("login-button"),
      userForms = document.getElementById("user_options-forms");

    signupButton.addEventListener(
      "click",
      () => {
        userForms.classList.remove("bounceRight");
        userForms.classList.add("bounceLeft");
      },
      false
    );

    loginButton.addEventListener(
      "click",
      () => {
        userForms.classList.remove("bounceLeft");
        userForms.classList.add("bounceRight");
      },
      false
    );
  }, []);

  return (
    <div className="loginSignupModalContainer">
      <section className="user">
        <div className="user_options-container">
          <div className="user_options-text">
            <div className="user_options-unregistered">
              <h2 className="user_unregistered-title">
                Don't have an account?
              </h2>
              <p className="user_unregistered-text">
                Join the go100 club, and help celebrate our 100th birthday.
              </p>
              <button className="user_unregistered-signup" id="signup-button">
                Register
              </button>
            </div>

            <div className="user_options-registered">
              <h2 className="user_registered-title">Have an account?</h2>
              <p className="user_registered-text">
                Welcome back! New adventures await.
              </p>
              <button className="user_registered-login" id="login-button">
                Login
              </button>
            </div>
          </div>

          <div className="user_options-forms" id="user_options-forms">
            <div className="user_forms-login">
              <h2 className="forms_title">Login</h2>
              <form className="forms_form">
                <fieldset className="forms_fieldset">
                  <div className="forms_field">
                    <input
                      type="email"
                      placeholder="Email"
                      className="forms_field-input"
                      required
                      autoFocus
                      onChange={updateLoginEmail}
                    />
                  </div>
                  <div className="forms_field">
                    <input
                      type="password"
                      placeholder="Password"
                      className="forms_field-input"
                      required
                      onChange={updateLoginPass}
                    />
                  </div>
                </fieldset>
                <div className="forms_buttons">
                  {/* <button type="button" className="forms_buttons-forgot">
                                        Forgot password?
                                    </button> */}
                  <input
                    type="submit"
                    value="Log In"
                    className="forms_buttons-action"
                    onClick={loginUser}
                  ></input>
                </div>
              </form>
            </div>

            <div className="user_forms-signup">
              <h2 className="forms_title">Register</h2>
              <form className="forms_form">
                <fieldset className="forms_fieldset">
                  <div className="forms_field">
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="forms_field-input"
                      required
                      onChange={updateRegName}
                      value={regName}
                    />
                  </div>
                  <div className="forms_field">
                    <input
                      type="email"
                      placeholder="Email"
                      className="forms_field-input"
                      required
                      onChange={updateRegEmail}
                      value={regEmail}
                    />
                  </div>
                  <div className="forms_field">
                    <input
                      type="password"
                      placeholder="Password"
                      className="forms_field-input"
                      required
                      onChange={updateRegPass}
                      value={regPass}
                    />
                  </div>
                </fieldset>
                <div className="forms_buttons">
                  <input
                    type="submit"
                    value="Sign up"
                    onClick={registerUser}
                    className="forms_buttons-action"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="close-btn" onClick={closeModal}>
            <i class="fal fa-times"></i>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginSignupModal;

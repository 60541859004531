import React, { useState } from "react";
import "./ForgotPassword.css";
import authInstance from "./firebase";
import Logo from "./images/Suzuki-Go-Lockup-x1.png";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const history = useHistory();

  const sendPasswordResetEmail = () => {
    authInstance
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log("Password reset email sent successfully");
        history.push("/login");
      })
      .catch(() => {
        alert("error");
      });
  };

  return (
    <div class="forgot-pass">
      <div className="forgot-pass-wrapper form-wrapper">
        <h2 className="forgot-pass-header">Forgot Password</h2>
        <img src={Logo} />
        <input
          type="email"
          placeholder="Email"
          className="forgot-pass-email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <p className="forgot-pass-text">
          Click submit to receive a password re-set link via email
        </p>
        <button
          className="forgot-pass-submit-btn submit-btn"
          onClick={sendPasswordResetEmail}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;

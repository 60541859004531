import React, { useState, useEffect } from "react";
import { CoordsProvider } from "./CoordsContext";
import firebase from "../firebase";

const WithGlobalCoords = ({ children }) => {
    const [globalCoords, setGlobalCoords] = useState({ test: 'test' });

    useEffect(() => {
        console.log('UE');
    });

    useEffect(() => {
        console.log('Global coords updated, ', globalCoords);
    }, [globalCoords]);

    return (
        <CoordsProvider
            value={{ globalCoords: globalCoords, setGlobalCoords: setGlobalCoords }}
        >
            {children}
        </CoordsProvider>
    );
};

export default WithGlobalCoords;

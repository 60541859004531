import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBLOiGie3nxYobJ2Wwd4UOamZS1CkoEpR8",
  authDomain: "sg100-294112.firebaseapp.com",
  databaseURL: "https://sg100-294112.firebaseio.com",
  projectId: "sg100-294112",
  storageBucket: "sg100-294112.appspot.com",
  messagingSenderId: "500088355364",
  appId: "1:500088355364:web:7fab1edd1bb2a2966676b1",
  measurementId: "G-G1C4T5W0B8",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

import React, { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import { message, notification } from "antd";
import "firebase/auth";
import "firebase/firestore";
import authInstance from "./firebase";
import "./LoginSignupModal.css";
import "./LandingPage.css";
import "./LoginSignupModal";
import LoginSignupModal from "./LoginSignupModal";
import Map from "./Map";
import Preloader from "./Preloader.js";

const LandingPage = (props) => {
  // const [displayLogin, setDisplayLogin] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(null); //was true

  const displayMessage = (msg) => {
    // message.success(msg);
    notification.open({
      message: msg,
      description: "Celebrate 100 years of Suzuki with 100 adventures!",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  const grayScaleStyle = { filter: "grayscale(100%)" };

  useEffect(() => {
    authInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        // console.log("Homepage: user is currently logged in.");
        setIsLoggedIn(user);
      } else {
        // console.log("Homepage: user is not currently logged in.");
        setIsLoggedIn(null);
      }
    });
  }, [isLoggedIn]);

  return (
    <div className="landingPage">
      {/* <div id="map" style={isLoggedIn ? null : grayScaleStyle}></div> */}
      <Preloader />
      <Map
        setPinCoords={props.setPinCoords}
        pinCoords={props.pinCoords}
        logModalVisible={props.logModalVisible}
        setLogModalVisible={props.setLogModalVisible}
        isLoggedIn={isLoggedIn}
        dropPinClicked={props.dropPinClicked}
        setDropPinClicked={props.setDropPinClicked}
      />
      {props.displayLogin ? (
        <LoginSignupModal
          setDisplayLogin={props.setDisplayLogin}
          displayMessage={displayMessage}
        />
      ) : null}
    </div>
  );
};

export default LandingPage;

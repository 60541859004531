import React, { useState } from "react";
import "./Login.css";
import authInstance from "./firebase";
import Logo from "./images/Suzuki-Go-Lockup-x1.png";
import { notification } from "antd";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitLogin = (e) => {
    if (!email) {
      notification["error"]({
        message: "Login error",
        description: "Please enter an email address",
      });
      return;
    }

    if (!password) {
      notification["error"]({
        message: "Login error",
        description: "Please enter a password",
      });
      return;
    }

    e.preventDefault();
    authInstance
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log("Successfully signed in");
        //Forward to home
        window.location.href = "/";
      })
      .catch(function (error) {
        console.log("Error signing in");
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
        notification["error"]({
          message: "Login error",
          description: errorMessage,
        });
      });
  };

  return (
    <div class="login">
      <div className="login-wrapper form-wrapper">
        <h2 className="login-header">Login</h2>
        <img src={Logo} />
        <input
          type="text"
          placeholder="Email"
          className="login-email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          className="login-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button className="login-submit-btn submit-btn" onClick={submitLogin}>
          Log in
        </button>
        <Link to="/register" className="xtra-btn">
          Register?
        </Link>
        <Link to="/forgotpassword" className="forgot-pass-btn">
          Forgot password?
        </Link>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import $ from "jquery";
import dt from "datatables.net";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "antd";

const Dashboard = () => {
  const [locations, setLocations] = useState([]);
  const [numLocations, setNumLocations] = useState(0);
  const [tableInit, setTableInit] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [attrs, setAttrs] = useState([
    "High ground clearance required",
    "4x4 only",
    "Available 24/7",
    "Entry fee applicable",
    "Bookings essential",
    "Wheelchair friendly",
    "Accessible by motorcycle only",
    "Accessible by boat only",
    "Child friendly",
    "Pets allowed",
    "Historic site",
    "Sunset Destination",
    "Sunrise Destination",
  ]);
  const [currentAttrs, setCurrentAttrs] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  function getLocations(fn) {
    axios
      .get("https://mysterious-citadel-66074.herokuapp.com/alllocations")
      .then((res) => {
        // console.log("fetched locations:");
        // console.log(res.data);
        setLocations(res.data);
        setNumLocations(res.data.length);
        // fn();
      });
  }

  const setDisplayAttrs = () => {
    let attrsToDisplay = [];
    attrs.map((attr, index) => {
      if (currentItem.attributes[index]) {
        console.log(currentItem.attributes[index]);
        // currentAttrs.push(attr);
        attrsToDisplay.push(attr);
        setCurrentAttrs(attrsToDisplay);
        // console.log(currentAttrs);
      }
    });
  };

  const setAsApproved = () => {
    if (currentItem.status == "approved") {
      alert("Location is already approved");
    } else {
      axios
        .post(
          `https://mysterious-citadel-66074.herokuapp.com/approvelocation/${query.get(
            "id"
          )}`
        )
        .then((res) => {
          alert("Approved");
        })
        .catch((err) => {
          alert(`Error making approve request: ${err}`);
        });
    }
  };

  useEffect(() => {
    getLocations();

    if (locations.length > 0 && !tableInit) {
      // console.log(locations);

      locations.forEach((item) => {
        item["view"] = "View";
      });

      $("#table_id").DataTable({
        data: locations,
        columns: [
          { data: "name" },
          { data: "description" },
          { data: "votes" },
          { data: "status" },
          {
            data: "view",
            render: function (data, type, listing) {
              return (
                "<a href='dashboard?id=" +
                listing["id"] +
                "' class='table-view-btn'>" +
                data +
                "</a>"
              );
            },
          },
        ],
      });

      setTableInit(true);
    }

    const id = query.get("id");

    if (id) {
      axios
        .get(`https://mysterious-citadel-66074.herokuapp.com/location/${id}`)
        .then((res) => {
          setCurrentItem(res.data[0]);
          console.log("Current item: ", currentItem);
          setDisplayAttrs();
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
  }, [numLocations]);

  if (query.get("id") == null) {
    return (
      <div className="dashboard-wrapper">
        <table id="table_id" class="display">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Votes</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className="response-wrapper">
        <div className="location-detail-item">
          <h3>Name:</h3>
          <p>{currentItem.name}</p>
        </div>
        <div className="location-detail-item">
          <h3>Coords:</h3>
          <p>{currentItem.coordinates}</p>
        </div>
        <div className="location-detail-item">
          <h3>Description:</h3>
          <p>{currentItem.description}</p>
        </div>
        <div className="location-detail-item">
          <h3>Logged by:</h3>
          <p>{currentItem.logged_by}</p>
        </div>
        <div className="location-detail-item">
          <h3>Vehicle:</h3>
          <p>{currentItem.vehicle}</p>
        </div>
        <div className="location-detail-item">
          <h3>Attributes:</h3>
          <ul>
            {currentAttrs.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
        </div>
        <div className="location-detail-item">
          <h3>Image:</h3>
          <img src={currentItem.image} />
        </div>
        <div className="location-detail-item">
          <h3>Votes:</h3>
          <p>{currentItem.votes}</p>
        </div>
        <div className="location-detail-item">
          <h3>Status:</h3>
          <p>{currentItem.status}</p>
        </div>
        <Button type="primary" onClick={setAsApproved}>
          Approve
        </Button>
      </div>
    );
  }
};

export default Dashboard;

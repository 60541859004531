import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserProvider } from "./UserContext";
import firebase from "../firebase";

const WithUser = ({ children }) => {
    const [user, setUser] = useState(null);

    let history = useHistory();

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
    });
    return (
        <UserProvider
            value={{ uid: user }}
        >
            {children}
        </UserProvider>
    );
};

export default WithUser;

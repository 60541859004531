import React from "react";
import "./Footer.css";
import authInstance from "firebase";

// function sendMail(){
//     var auth = authInstance.auth();
//     var emailAddress = "ryan@pixel.joburg";

//     auth.sendPasswordResetEmail(emailAddress).then(function() {
//         console.log("Email sent");
//     }).catch(function(error) {
//         console.log("Failed to send email");
//     });
// }

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className="copyright-wrapper">
                <p>© 2020 Suzuki Go 100 Adventures.</p>
            </div>
            <div className="nav-icons-wrapper">
                <a href="https://twitter.com/Suzuki_ZA/?lang=en">
                    <i className="fa fa-twitter"></i>
                </a>
                <a href="https://www.facebook.com/SuzukiAutoSA">
                    <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHqs67kP9XWWAAAAXVQpHbIhNf97Y2wOeLLSWSeTroU1PMAolPi5vo0zBxd4WJyV5pfERLsH8YciH0gKJjBO5BZz7No1pSI1L5znQewv8p7spEQLuYxpzpchGH1DNGQVN829rY=&originalReferer=https://www.suzukiauto.co.za/?utm_source=adwords&utm_medium=ppc&utm_campaign=ALWAYS+SEARCH+2019&utm_term=&hsa_acc=5852125428&hsa_tgt=dsa-785056790299&hsa_grp=78944706369&hsa_net=adwords&hsa_ver=3&hsa_&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fsuzuki-auto-south-africa-0697b165%2F">
                    <i className="fa fa-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/SuzukiAutoSA">
                    <i className="fa fa-youtube-play"></i>
                </a>
                <a href="https://instagram.com/suzuki_za">
                    <i className="fa fa-instagram"></i>
                </a>
                {/* <button onClick={sendMail}>Send</button> */}
            </div>
        </div>
    );
};

export default Footer;

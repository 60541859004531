import React, { useEffect, useState } from "react";
import "./LeaderItem.css";
import axios from "axios";

const LeaderItem = (props) => {
  const logVote = () => {
    let voteData = { name: props.name, uid: props.uid };

    axios
      .post("https://mysterious-citadel-66074.herokuapp.com/vote", voteData)
      // .post("http://localhost:5000/vote", voteData)
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => {
        if (err) {
          alert("You have already voted for this location.");
        }
        // alert("You have already voted for this location.");
      });
  };

  useEffect(() => {
    props.setDisplayPreloader(false);
    if (props.name === "Dune 7 - A view that's worth the climb") {
      console.log("DUNE 7:", props.img);
    }
  });

  return (
    <div className="leader-item">
      <div
        className="leader-img"
        style={{ backgroundImage: `url('${props.img}')` }}
      >
        {/* <p>{props.img}</p> */}
      </div>
      <div className="leader-excerpt">
        <h3
          id={props.id}
          className="leader-item-header"
          onClick={props.showModal}
        >
          {props.name}{" "}
          <span>
            <i class="fas fa-heart" id={props.id}></i> {props.votes}
          </span>
        </h3>
        <p>{props.excerpt}</p>
      </div>
      {props.uid ? (
        <a onClick={logVote} className="leader-vote-button">
          <i class="fas fa-heart-circle fa-3x"></i>
        </a>
      ) : (
        <a
          onClick={() => {
            window.location.href = "/login";
          }}
          className="leader-vote-button"
        >
          <i class="fas fa-heart-circle fa-3x"></i>
        </a>
      )}
    </div>
  );
};

export default LeaderItem;

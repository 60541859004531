import React, { useState, useEffect } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import authInstance from "./firebase";
import { UploadOutlined, SmileOutlined } from "@ant-design/icons";
// import { SmileOutlined } from '@ant-design/icons';
import {
  Form,
  Alert,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Checkbox,
  Progress,
  notification,
  Switch,
  message,
  Modal,
  Row,
  Col,
  Upload,
  Popover,
  Tooltip
} from "antd";
import "./NavBar.css";
import plusIcon from "./images/plus_icon.svg";
import brand from "./images/Suzuki-Go-Lockup-x1.png";
import LeaderBoard from "./LeaderBoard";
import axios from "axios";
import { OmitProps } from "antd/lib/transfer/ListBody";
import { Link } from "react-router-dom";
import Compressor from "compressorjs";
import $ from "jquery";
import { useLocation, useHistory } from "react-router-dom";
import nodemailer from "nodemailer";

const NavBar = (props) => {
  // const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visibleHamburger, setVisibleHamburger] = useState(false);
  const [confirmLoading2, setConfirmLoading2] = useState(false);
  const [confirmLoadingHamburger, setConfirmLoadingHamburger] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDisplayImg, setUserDisplayImg] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [appUser, setAppUser] = useState({});
  const [URLQuery, setURLQuery] = useState("");

  const [uploadedImage, setUploadedImage] = useState(null);
  const [formName, setFormName] = useState("");
  const [formCoords, setFormCoords] = useState([0, 0]);
  const [formCoordsString, setFormCoordsString] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formVehicle, setFormVehicle] = useState("");
  const [formAttributes, setFormAttributes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [displayOtherField, setDisplayOtherField] = useState(false);

  // const [formLatitudeDeg, setFormLatitudeDeg] = useState({ lat: 0, long: 0 });
  const [formLatitudeDeg, setFormLatitudeDeg] = useState("");
  const [formLatitudeMin, setFormLatitudeMin] = useState("");
  const [formLatitudeSec, setFormLatitudeSec] = useState("");
  const [formLongitudeDeg, setFormLongitudeDeg] = useState("");
  const [formLongitudeMin, setFormLongitudeMin] = useState("");
  const [formLongitudeSec, setFormLongitudeSec] = useState("");
  const [coordsFormStyle, setCoordsFormStyle] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [displayLogForm, setDisplayLogForm] = useState(false);

  const history = useHistory();

  useEffect(() => {
    console.log('pinCoords received ', props.pinCoords);
    if (props.pinCoords.length > 0) {
      DDtoDMS(props.pinCoords[0], props.pinCoords[1]);
    }

  }, [props.pinCoords]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const DDtoDMS = (initLat, initLong) => {
    // console.log(initLat, initLong);
    console.log(`DDtoDMS ${initLat} ${initLong}`);
    let latDeg = Math.floor(initLat * -1);

    let longDeg = Math.floor(initLong);

    let latMin = Math.floor((initLat * -1 - latDeg) * 60);
    let longMin = Math.floor((initLong - longDeg) * 60);

    let latSec = ((initLat * -1 - latDeg) * 60 - latMin) * 60;
    let longSec = ((initLong - longDeg) * 60 - longMin) * 60;

    setFormLatitudeDeg(latDeg);
    setFormLongitudeDeg(longDeg);
    setFormLatitudeMin(latMin);
    setFormLongitudeMin(longMin);
    setFormLatitudeSec(latSec);
    setFormLongitudeSec(longSec);

    // console.log(formLatitudeDeg);
    // console.log(formLongitudeDeg);
    // console.log(formLatitudeMin);
    // console.log(formLongitudeMin);
    // console.log(formLatitudeSec);
    // console.log(formLongitudeSec);
  };

  function showModal() {
    if (authInstance.auth().currentUser) {
      props.setLogModalVisible(true);
      // let form = Form.useForm();
      // form.resetFields();

      // let initLat = props.pinCoords[0];
      // let initLong = props.pinCoords[1];
      // if (props.pinCoords.length > 0) {
      //   console.log("Doing conversion");
      //   DDtoDMS(props.pinCoords[0], props.pinCoords[1]);
      // }
    } else {
      // props.setDisplayLogin(true);
      // window.location.href = '/login';
      // <Redirect to={"/login"} />
    }
  }

  function showModal2() {
    // if (authInstance.auth().currentUser) {
    //   setVisible2(true);
    // } else {
    //   // props.setDisplayLogin(true);
    //   // window.location.href = '/login';
    //   // <Redirect to={"/login"} />
    // }
    setVisible2(true);
  }

  function handleOk() {
    // DMStoDD();
    // setFormCoordsString(
    //     `${formLatitudeDeg}°${formLatitudeMin}\'${formLatitudeSec}\"S+${formLongitudeDeg}°${formLongitudeMin}\'${formLongitudeSec}\"E`
    // );
    // setFormCoordsString("test should display");

    if (!formName) {
      notification["error"]({
        message: "Error",
        description: "'Destination Name' cannot be blank",
      });

      return;
    }

    if (
      !formLatitudeDeg ||
      !formLatitudeMin ||
      !formLatitudeSec ||
      !formLongitudeDeg ||
      !formLongitudeMin ||
      !formLongitudeSec
    ) {
      notification["error"]({
        message: "Error",
        description: "Coordinates cannot be blank",
      });

      return;
    }

    if (!formDescription) {
      notification["error"]({
        message: "Error",
        description: "'Description' cannot be blank",
      });

      return;
    }

    if (!formVehicle) {
      notification["error"]({
        message: "Error",
        description: "'Vehicle' cannot be blank",
      });

      return;
    }

    if (!formDescription) {
      notification["error"]({
        message: "Error",
        description: "'Description' cannot be blank",
      });

      return;
    }

    if (!uploadedImage) {
      notification["error"]({
        message: "Error",
        description: "Please upload an image",
      });

      return;
    }

    console.log(formLatitudeDeg);
    console.log(formLatitudeMin);
    console.log(formLatitudeSec);
    console.log(formLongitudeDeg);
    console.log(formLongitudeMin);
    console.log(formLongitudeSec);
    console.log("--------------------");

    console.log(formName);
    console.log(formCoords);
    console.log(formDescription);
    console.log(formVehicle);
    console.log(formAttributes);
    console.log(uploadedImage);
    console.log(formCoordsString);

    console.log("APP USER UID: ", appUser.uid);

    let newLocationsData = {
      name: formName,
      lat: [formLatitudeDeg, formLatitudeMin, formLatitudeSec],
      long: [formLongitudeDeg, formLongitudeMin, formLongitudeSec],
      description: formDescription,
      vehicle: formVehicle,
      attributes: formAttributes,
      image: uploadedImage,
      logged_by: appUser.displayName,
      logged_by_id: appUser.uid,
      votes: 0,
      status: "pending",
    };

    console.log("DATA TO BE POSTED:");
    console.log(newLocationsData);

    axios
      .post(
        "https://mysterious-citadel-66074.herokuapp.com/newlocation",
        // "http://localhost:5000/newlocation",
        newLocationsData
      )
      .then((res) => {
        message.success(res.message);
        openSuccessNotification();
        window.location.reload();
      })
      .catch((err) => {
        message.error(err);
      });

    props.setLogModalVisible(false);
  }

  const openSuccessNotification = () => {
    notification.open({
      message: "Thank you!",
      description:
        "Your location has been submitted for moderation. Check back soon!",
      icon: <SmileOutlined style={{ color: "#a7c235" }} />,
    });
  };

  function handleOk2() {
    setVisible2(false);
  }

  function handleOkHamburger() {
    setVisibleHamburger(false);
  }

  function handleCancel() {
    props.setLogModalVisible(false);
  }

  function handleCancel2() {
    setVisible2(false);
  }

  function handleCancelHamburger() {
    setVisibleHamburger(false);
  }

  function updateUserName(name) {
    console.log("Updating username on registration");
    setUserName(name);
  }

  function logoutUser() {
    authInstance
      .auth()
      .signOut()
      .then(function () {
        setIsLoggedIn(false);
        window.location.reload();
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function uploadOnChange(e) {
    if (e.target.files[0].size < 6500000) {
      const reader = new FileReader();
      const now = new Date();
      const day = now.getDay();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const seconds = now.getSeconds();
      const milliSec = now.getMilliseconds();
      var imagesRef = authInstance
        .storage()
        .ref()
        .child(
          `POIImages/${formName}${day}${hour}${minute}${seconds}${milliSec}`
        );

      new Compressor(e.target.files[0], {
        quality: 0.3,
        success(result) {
          console.log("Successfully compressed");
          // imagesRef
          //   .put(result)
          //   .then((snapshot) => {
          //     console.log("Uploaded image to storage");
          //     setUploadedImage(imagesRef.fullPath);
          //   })
          //   .catch((err) => {
          //     notification["error"]({
          //       message: "Error",
          //       description: err,
          //     });
          //   });
          let uploadTask = imagesRef.put(result);

          uploadTask.on(
            authInstance.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
              setUploadProgress(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
            },
            (err) => {
              notification["error"]({
                message: "Error",
                description: err,
              });
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  console.log("Download URL: ", downloadURL);
                  setUploadedImage(downloadURL);
                });
            }
          );
        },
        error(err) {
          console.log(err.message);
        },
      });

      // imagesRef.put(e.target.files[0]).then((snapshot) => {
      //   console.log("Uploaded image to storage");
      //   setUploadedImage(imagesRef.fullPath);
      // });
    } else {
      notification["error"]({
        message: "File too large",
        description: "Please upload a smaller image",
      });
      e.target.files = null;
    }
    // setUploadedImage(await toBase64(e.target.files[0]));
  }

  function nameOnChange(e) {
    setFormName(e.target.value);
  }

  function latitudeDegOnChange(e) {
    setFormLatitudeDeg(e.target.value);
  }
  function latitudeMinOnChange(e) {
    setFormLatitudeMin(e.target.value);
  }
  function latitudeSecOnChange(e) {
    setFormLatitudeSec(e.target.value);
  }
  function longitudeDegOnChange(e) {
    setFormLongitudeDeg(e.target.value);
  }
  function longitudeMinOnChange(e) {
    setFormLongitudeMin(e.target.value);
  }
  function longitudeSecOnChange(e) {
    setFormLongitudeSec(e.target.value);
  }

  function descriptionOnChange(e) {
    setFormDescription(e.target.value);
  }

  function vehicleOnChange(e) {
    if (e == "Other") {
      setDisplayOtherField(true);
      return;
    } else {
      setDisplayOtherField(false);
      setFormVehicle(e);
    }
    // console.log(e);
    // console.log(`Vehicle: ${formVehicle}`);
  }

  function otherVehicleOnChange(e) {
    setFormVehicle(e.target.value);
    // console.log(e);
    console.log(`Vehicle: ${formVehicle}`);
  }

  function attributesOnChange(e) {
    let tempArr = formAttributes;
    tempArr[e.target.value] = e.target.checked;
    setFormAttributes(tempArr);
    console.log(`Attr: ${formAttributes}`);
  }

  function DMStoDD() {
    console.log(formLatitudeDeg);
    let convertedLat =
      parseInt(formLatitudeDeg) +
      parseInt(formLatitudeMin) / 60 +
      parseInt(formLatitudeSec) / 3600;
    console.log(convertedLat);
    let convertedLong =
      parseInt(formLongitudeDeg) +
      parseInt(formLongitudeMin) / 60 +
      parseInt(formLongitudeSec) / 3600;
    console.log(convertedLong);
    let coordsGenerated = { lat: convertedLat, long: convertedLong };
    console.log(coordsGenerated.lat);
    console.log(coordsGenerated.long);

    setFormCoords([12, 23]);
    console.log(`COORDS: ${formCoords[0]} ${formCoords[1]}`);
  }

  // function onFinish(values) {
  //     console.log("SUCCESS", values);
  // }

  // function test(e) {
  //     console.log(e.target.value);
  // }

  function openSignInModal() {
    // props.setDisplayLogin(true);
    window.location.href = "/login";
  }

  const currentLocOnChange = (e) => {
    if (e.target.checked) {
      setCoordsFormStyle(true);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latDeg = Math.floor(position.coords.latitude * -1);

          let longDeg = Math.floor(position.coords.longitude);

          let latMin = Math.floor(
            (position.coords.latitude * -1 - latDeg) * 60
          );
          let longMin = Math.floor((position.coords.longitude - longDeg) * 60);

          let latSec =
            ((position.coords.latitude * -1 - latDeg) * 60 - latMin) * 60;
          let longSec =
            ((position.coords.longitude - longDeg) * 60 - longMin) * 60;

          setFormLatitudeDeg(latDeg);
          setFormLongitudeDeg(longDeg);
          setFormLatitudeMin(latMin);
          setFormLongitudeMin(longMin);
          setFormLatitudeSec(latSec);
          setFormLongitudeSec(longSec);

          console.log(latDeg);
          console.log(longDeg);
          console.log(latMin);
          console.log(longMin);
          console.log(latSec);
          console.log(longSec);
        },
        () => {
          alert("Unable to retrieve your current location");
        }
      );
    } else {
      setCoordsFormStyle(false);
    }
  };

  useEffect(() => {
    setURLQuery(query.get("location"));
    if (props.pinCoords.length > 0) {
      DDtoDMS(props.pinCoords[0], props.pinCoords[1]);
    }
    authInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        setAppUser(user);
        setIsLoggedIn(true);

        if (query.get("location") == "log") {
          console.log("Triggering click");
          $("#log-btn").trigger("click");
        }

        if (query.get("location") == "vote") {
          console.log("Triggering click");
          $("#vote-btn").trigger("click");
        }
      } else {
        if (query.get("location") == "log") {
          window.location.href = "/login";
        }

        if (query.get("location") == "vote") {
          console.log("Triggering click");
          $("#vote-btn").trigger("click");
        }
        // setIsLoggedIn(false);
      }
    });
    // }, [props.pinCoords[0]]);
  }, [URLQuery]);

  const showHamburger = (e) => {
    e.preventDefault();
    setVisibleHamburger(true);
  };

  const content = (
    <div>
      <p>Click here to drop a pin at your current location.</p>
      <p>Move the pin to log a location at a custom destination of your choice.</p>
    </div>
  );

  return (
    <div className="nav-wrapper">
      <div className="nav-brand-wrapper">
        <a href="https://suzukigo100.co.za/">
          <img src={brand} alt="" />
        </a>
      </div>
      <div className="nav-clickables-wrapper">
        {isLoggedIn ? (
          <div className="user-display">
            <img src={appUser.photoURL} />
            <p className="user-greeting">Hello, {appUser.displayName}!</p>
            <span className="logout-link" onClick={logoutUser}>
              Logout
            </span>
          </div>
        ) : (
            <div className="user-display">
              <Link to="/login" className="user-greeting">
                Sign in/Register
            </Link>
            </div>
          )}

        <div className="nav-links-wrapper">
          <ul>
            <li>
              <a href="https://suzukigo100.co.za/">Home</a>
            </li>
            <li>
              <a href="https://suzukigo100.co.za/activities/">Activities</a>
            </li>
            <li>
              <a href="https://suzukigo100.co.za/history/">Suzuki History</a>
            </li>
          </ul>
        </div>

        {authInstance.auth().currentUser ? (
          <div className="nav-btn-wrapper">
            <Popover content={content} title='Drop a pin'>
              <Button className="drop-pin-nav-btn" onClick={() => {
                console.log('Triggering drop pin');
                // props.setPinCoords({ action: 'drop pin' });
                props.setDropPinClicked(true);
              }}>
                <i class="fas fa-map-pin fa-lg drop-pin-icon"></i>
              </Button>
            </Popover>
            <button
              className="btn btn-green"
              id="vote-btn"
              onClick={showModal2}
            >
              <i className="fas fa-heart space-right"></i>Leaderboard
            </button>
            <button
              className="btn btn-pink-outline log-btn"
              id="log-btn"
              onClick={showModal}
            >
              <img src={plusIcon} className="btn-icon space-right" />
              Log your own
            </button>
            <p onClick={showHamburger} className="burger">
              <i class="fal fa-bars fa-lg"></i>
            </p>
          </div>
        ) : (
            <div className="nav-btn-wrapper">
              {/* <Link className="btn btn-green" to="/login">
              <i className="fas fa-heart space-right"></i>Leaderboard
            </Link> */}
              <button
                className="btn btn-green"
                id="vote-btn"
                onClick={showModal2}
              >
                <i className="fas fa-heart space-right"></i>Leaderboard
            </button>
              <a
                className="btn btn-pink-outline log-btn"
                id="log-btn"
                onClick={() => {
                  history.push("/login");
                }}
              >
                <img src={plusIcon} className="btn-icon space-right" />
              Log your own
            </a>
              <p onClick={showHamburger} className="burger">
                <i class="fal fa-bars fa-lg"></i>
              </p>
            </div>
          )}

        {authInstance.auth().currentUser ? (
          <div className="nav-btn-wrapper-mobile">
            <button className="" onClick={showModal2}>
              <i className="fas fa-heart space-right"></i>
            </button>
            <button className="" onClick={showModal}>
              <img src={plusIcon} className="btn-icon space-right" />
            </button>
            <p onClick={showHamburger} className="burger">
              <i class="fal fa-bars fa-lg"></i>
            </p>
          </div>
        ) : (
            <div className="nav-btn-wrapper-mobile">
              <Link className="" to="/login">
                <i className="fas fa-heart space-right"></i>
              </Link>
              <Link className="" to="/login">
                <img src={plusIcon} className="btn-icon space-right" />
              </Link>
              <p onClick={showHamburger} className="burger">
                <i class="fal fa-bars fa-lg"></i>
              </p>
            </div>
          )}
      </div>
      <Modal
        title="Log a new destination"
        visible={props.logModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          size={"default"}
          initialValues={{
            latitudeDeg: formLatitudeDeg,
            latitudeMin: formLatitudeMin,
            latitudeSec: formLatitudeSec,
            longitudeDeg: formLongitudeDeg,
            longitudeMin: formLongitudeMin,
            longitudeSec: formLongitudeSec,
          }}
        >
          {/* <Form.Item label="Form Size" name="size">
                        <Radio.Group>
                            <Radio.Button value="small">Small</Radio.Button>
                            <Radio.Button value="default">Default</Radio.Button>
                            <Radio.Button value="large">Large</Radio.Button>
                        </Radio.Group>
                    </Form.Item> */}
          <Form.Item label="Destination name" rules={[{ required: true }]}>
            <Input onChange={nameOnChange} />
          </Form.Item>

          {/* <Form.Item
            name="use-my-location-group"
            label="Use current location"
          >
            <Row>
              <Col span={24}>
                <Checkbox
                  value="currentLoc"
                  style={{ lineHeight: "32px" }}
                  onChange={currentLocOnChange}
                >
                </Checkbox>
              </Col>
            </Row>
          </Form.Item> */}

          <Form.Item label="Latitude" style={{ marginBottom: 0 }}>
            <div
              name="latitudeDeg"
              // value={formLatitudeDeg}
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder="°"
                type="text"
                value={formLatitudeDeg}
                disabled={coordsFormStyle}
                // disabled="true"
                onChange={latitudeDegOnChange}
                required
              />
            </div>
            <div
              name="latitudeMin"
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder="'"
                type="text"
                value={formLatitudeMin}
                disabled={coordsFormStyle}
                onChange={latitudeMinOnChange}
                required
              />
            </div>
            <div
              name="latitudeSec"
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder='"'
                type="text"
                value={formLatitudeSec}
                disabled={coordsFormStyle}
                onChange={latitudeSecOnChange}
                required
              />
            </div>
          </Form.Item>
          <Form.Item label="Longitude" style={{ marginBottom: 0 }}>
            <div
              name="longitudeDeg"
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder="°"
                type="text"
                value={formLongitudeDeg}
                disabled={coordsFormStyle}
                onChange={longitudeDegOnChange}
                required
              />
            </div>
            <div
              name="longitudeMin"
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder="'"
                type="text"
                value={formLongitudeMin}
                disabled={coordsFormStyle}
                onChange={longitudeMinOnChange}
                required
              />
            </div>
            <div
              name="longitudeSec"
              // rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(33% - 8px)" }}
            >
              <input
                placeholder='"'
                type="text"
                value={formLongitudeSec}
                disabled={coordsFormStyle}
                onChange={longitudeSecOnChange}
                required
              />
            </div>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea
              placeholder="Please provide a short description of the point of interest including access points and if an entrance fee applies, as well as any special instructions"
              height="300"
              onChange={descriptionOnChange}
              style={{ height: "100px" }}
            />
          </Form.Item>

          <Form.Item label="Vehicle" rules={[{ required: true }]}>
            <Select onChange={vehicleOnChange}>
              <Select.Option value="All">All</Select.Option>
              <Select.Option value="Baleno">Baleno</Select.Option>
              <Select.Option value="Belerio">Celerio</Select.Option>
              <Select.Option value="Ciaz">Ciaz</Select.Option>
              <Select.Option value="Dzire">Dzire</Select.Option>
              <Select.Option value="Ertiga">Ertiga</Select.Option>
              <Select.Option value="Ignis">Ignis</Select.Option>
              <Select.Option value="Jimny">Jimny</Select.Option>
              <Select.Option value="S-presso">S-presso</Select.Option>
              <Select.Option value="Super Carry">Baleno</Select.Option>
              <Select.Option value="Swift">Swift</Select.Option>
              <Select.Option value="Swift Sport">Swift Sport</Select.Option>
              <Select.Option value="Vitara">Vitara</Select.Option>
              <Select.Option value="Adventure Motorcycle">
                Adventure Motorcycle
              </Select.Option>
              <Select.Option value="Superbike">Superbike</Select.Option>
              <Select.Option value="Cruiser">Cruiser</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Other"
            rules={[{ required: false }]}
            style={
              displayOtherField ? { display: "flex" } : { display: "none" }
            }
          >
            <Input onChange={otherVehicleOnChange} />
          </Form.Item>

          {/* <Form.Item label="Logged by" rules={[{ required: false }]}>
                        <Input onChange={loggedByOnChange} placeholder="Optional"/>
                    </Form.Item> */}

          <Form.Item
            name="checkbox-group"
            label="POI Attributes"
            rules={[{ required: true }]}
          >
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <Checkbox
                    value="0"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    High ground clearance required
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="1"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    4x4 only
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="2"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Available 24/7
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="3"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Entry fee applicable
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="4"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Bookings essential
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="5"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Wheelchair friendly
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="6"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Accessible by motorcycle only
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="7"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Accessible by boat only
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="8"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Child friendly
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="9"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Pets allowed
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="10"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Historic site
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="11"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Sunset Destination
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox
                    value="12"
                    style={{ lineHeight: "32px" }}
                    onChange={attributesOnChange}
                  >
                    Sunrise Destination
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          {/* <Upload name="image-upload" onChange={uploadOnChange} rules={[{ required: true }]}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload> */}
          <div className="upload-wrapper">
            <label className="ant-col-6">Image:</label>
            <div className="image-upload">
              <input type="file" accept="image/*" onChange={uploadOnChange} />
            </div>
            <label className="max-filesize">Max file size: 5MB</label>
          </div>
          {uploadProgress > 0 ? (
            <Progress percent={uploadProgress} size="small" />
          ) : null}
        </Form>
      </Modal>

      <Modal
        title="Go100 Adventure Leaders"
        visible={visible2}
        onOk={handleOk2}
        confirmLoading={confirmLoading2}
        onCancel={handleCancel2}
      >
        <LeaderBoard />
      </Modal>

      <Modal
        className="green-m"
        title="Menu"
        visible={visibleHamburger}
        onOk={handleOkHamburger}
        confirmLoading={confirmLoadingHamburger}
        onCancel={handleCancelHamburger}
      >
        <div>
          <a onClick={(e) => {
            e.preventDefault();
            console.log('Triggering drop pin');
            // props.setPinCoords({ action: 'drop pin' });
            props.setDropPinClicked(true);
            handleCancelHamburger();
          }}><h3>Drop pin</h3></a>
          <a href="https://suzukigo100.co.za/" className="mobile-link">
            <h3>Home</h3>
          </a>
          <a href="/">
            <h3>Go100 map</h3>
          </a>
          <a href="https://map.suzukigo100.co.za/?location=vote">
            <h3>Vote now</h3>
          </a>
          <a href="https://map.suzukigo100.co.za/?location=log">
            <h3>Log a destination</h3>
          </a>
          <a
            href="https://suzukigo100.co.za/activities/"
            className="mobile-link"
          >
            <h3>Activities</h3>
          </a>
          <a href="https://suzukigo100.co.za/history/" className="mobile-link">
            <h3>Suzuki history</h3>
          </a>
          <a href="/?location=register">
            <h3>Sign up</h3>
          </a>
          <a href="/?location=login">
            <h3>Login</h3>
          </a>
          <a href="https://www.suzukiauto.co.za/contact-us/" target="_blank">
            <h3>Get in touch</h3>
          </a>
        </div>
      </Modal>
    </div>
  );
};

export default NavBar;

// import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";
import React, { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import authInstance from "./firebase";
import "../node_modules/antd/dist/antd.css";
import "./ant-overwrite.css";
import LandingPage from "./LandingPage";
import "./App.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Signup from "./Signup";
import ConfirmDetails from "./ConfirmDetails";
import Dashboard from "./Dashboard";
import ForgotPassword from "./ForgotPassword";
import WithUser from "./User/User";
import WithGlobalCoords from "./Coords/Coords";

const App = () => {
  // const [displayLogin, setDisplayLogin] = useState(false);

  useEffect(() => {
    // console.log('WithGlobalCoords ', WithGlobalCoords);
    // var firebaseConfig = {
    //     apiKey: "AIzaSyDJkkPhVnMrJGLj1N1q7DKl6X0aN3RGN80",
    //     authDomain: "suzuki-go100.firebaseapp.com",
    //     databaseURL: "https://suzuki-go100.firebaseio.com",
    //     projectId: "suzuki-go100",
    //     storageBucket: "suzuki-go100.appspot.com",
    //     messagingSenderId: "484739901475",
    //     appId: "1:484739901475:web:677e42748f4b1ec263a996",
    //     measurementId: "G-YBPRC7YXNL",
    // };
    // firebase.initializeApp(firebaseConfig);
  });

  return (
    <div className="App">
      <WithUser>
        {/* <WithGlobalCoords> */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/register">
            <Signup />
          </Route>
          <Route exact path="/confirmdetails">
            <ConfirmDetails />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/dashboard/">
            <Dashboard />
          </Route>
        </Switch>
        {/* </WithGlobalCoords> */}
      </WithUser>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import "./LeaderBoard.css";
import placeholderImage1 from "./images/placeholder1.png";
import placeholderImage2 from "./images/placeholder2.png";
import LeaderItem from "./LeaderItem";
import axios from "axios";
import authInstance from "./firebase";
import Preloader from "./Preloader";
import { Modal } from "antd";

const LeaderBoard = () => {
  const [locations, setLocations] = useState([]);
  const [locationsSize, setLocationsSize] = useState(0);
  const [displayPreloader, setDisplayPreloader] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [visible, setVisible] = useState(false);
  const [leaderLocation, setLeaderLocation] = useState({});
  const [attrsList, setAttrsList] = useState([
    "High ground clearance required",
    "4x4 only",
    "Available 24/7",
    "Entry fee applicable",
    "Bookings essential",
    "Wheelchair friendly",
    "Accessible by motorcycle only",
    "Accessible by boat only",
    "Child friendly",
    "Pets allowed",
    "Historic site",
    "Sunset Destination",
    "Sunrise Destination",
  ]);
  const [currentAttrs, setCurrentAttrs] = useState([]);

  const showModal = (e) => {
    console.log(e.target.id);
    setVisible(true);
    getLeaderItem(e.target.id);
  };

  const getLeaderItem = (id) => {
    axios
      // .get("https://mysterious-citadel-66074.herokuapp.com/leaderlocations")
      .get(`https://mysterious-citadel-66074.herokuapp.com/location/${id}`)
      .then((res) => {
        setLeaderLocation(res.data[0]);
        let temp = [];

        attrsList.map((attr, index) => {
          if (res.data[0].attributes[index]) {
            temp.push(attr);
          }
        });

        setCurrentAttrs(temp);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  let loggedUser = authInstance.auth().currentUser;

  const renderLeaderItem = (item) => {
    const excerptSnippet = item.description.slice(0, 51) + "...";

    // return (
    //   <LeaderItem
    //     img={item.image}
    //     name={item.name}
    //     votes={item.votes}
    //     excerpt={excerptSnippet}
    //     // uid={loggedUser.uid}
    //     setDisplayPreloader={setDisplayPreloader}
    //   />
    // );

    // if (item.id == "rR0W6HQINxsy02ZVOcnZ") {
    //   console.log("///////////////:");
    // console.log(item.image);
    // }

    if (loggedIn) {
      return (
        <LeaderItem
          id={item.id}
          img={item.image}
          name={item.name}
          votes={item.votes}
          excerpt={excerptSnippet}
          uid={loggedUser.uid}
          setDisplayPreloader={setDisplayPreloader}
          showModal={showModal}
        />
      );
    } else {
      return (
        <LeaderItem
          id={item.id}
          img={item.image}
          name={item.name}
          votes={item.votes}
          excerpt={excerptSnippet}
          setDisplayPreloader={setDisplayPreloader}
          showModal={showModal}
        />
      );
    }
  };

  useEffect(() => {
    axios
      // .get("https://mysterious-citadel-66074.herokuapp.com/leaderlocations")
      .get("https://mysterious-citadel-66074.herokuapp.com/leaderlocations")
      .then((res) => {
        setLocations(res.data);
        console.log(res.data);
        setLocationsSize(res.data.length);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

    authInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log("Logged in leaderboard");
        console.log(user);
        setLoggedIn(true);
      } else {
        console.log("Logged out leaderboard");
        setLoggedIn(false);
      }
    });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("https://mysterious-citadel-66074.herokuapp.com/leaderlocations")
  //     // .get("http://localhost:5000/leaderlocations")
  //     .then((res) => {
  //       setLocations(res.data);
  //       console.log(res.data.length);
  //       setLocationsSize(res.data.length);
  //     })
  //     .catch((err) => {
  //       console.log("Error: ", err);
  //     });

  //    authInstance.auth().onAuthStateChanged(use=>{
  //      if(user){
  //        setLoggedIn(true);
  //      } else {
  //        setLoggedIn(false);
  //      }
  //    })
  // });

  return (
    <div className="leader-wrap">
      {displayPreloader ? <Preloader /> : null}
      {locations.map(renderLeaderItem)}

      <Modal
        title={leaderLocation.name}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="leader-location-wrapper">
          <img src={leaderLocation.image} />
          <h2>{leaderLocation.name}</h2>
          <p>
            <strong>Logged by: </strong>
            {leaderLocation.logged_by}
          </p>
          <p>{leaderLocation.coordinates}</p>
          <p>{leaderLocation.description}</p>
          <p>
            <strong>Vehicle: </strong>
            {leaderLocation.vehicle}
          </p>
          {currentAttrs.map((item) => {
            return (
              <div class="leader-attribute">
                <i class="fal fa-check-circle"></i> {item}
              </div>
            );
          })}
        </div>
      </Modal>

      {/* <p className="votes">Votes are still coming in, check back soon...</p> */}
    </div>
  );
};

export default LeaderBoard;

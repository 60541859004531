import React, { useState, useEffect, useContext } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import LandingPage from "./LandingPage";
import { useLocation, useHistory } from "react-router-dom";
import authInstance from "./firebase";

const Home = () => {
  const [displayLogin, setDisplayLogin] = useState(false);
  const [displayLogForm, setDisplayLogForm] = useState(false);
  const [displayVoteForm, setDisplayVoteForm] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [pinCoords, setPinCoords] = useState([]);
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [dropPinClicked, setDropPinClicked] = useState(false);
  // const globalCoords = useContext(CoordsContext);

  const history = useHistory();

  useEffect(() => {
    if (dropPinClicked) {
      console.log('HOME - dropPinClicked');
    } else {
      console.log('HOME - dropPinClicked - FALSE');
    }
  }, [dropPinClicked]);

  const setPinCoordsProp = (coords) => {
    console.log('setting Home Coords', coords);
    setPinCoords(coords);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    authInstance.auth().onAuthStateChanged(function (user) {
      if (user) {
        // setLoggedIn(true);
        // if (query.get("location") == "log") {
        // console.log("Logged in and log page requested");
        //     setDisplayLogForm(true);
        //   } else if (query.get("location") == "vote") {
        //     // console.log("Logged in and log page requested");
        //     setDisplayVoteForm(true);
        //   }
        // } else {
        //   setLoggedIn(false);
        //   if (query.get("location") == "log" || query.get("location") == "vote") {
        //     history.push("/login");
        //   }
      }
    });
  }, [pinCoords]);

  let query = useQuery();

  if (query) {
    if (query.get("location") == "login") {
      history.push("/login");
    } else if (query.get("location") == "register") {
      history.push("/register");
    } else if (query.get("location") == "confirm") {
      history.push("/confirmdetails");
    } else if (query.get("location") == "dashboard") {
      if (query.get("id")) {
        history.push("/dashboard?id=" + query.get("id"));
      } else {
        history.push("/dashboard");
      }
    }
  }

  return (
    <div>
      <NavBar
        displayLogin={displayLogin}
        displayLogForm={displayLogForm}
        displayVoteForm={displayVoteForm}
        setDisplayLogin={setDisplayLogin}
        pinCoords={pinCoords}
        logModalVisible={logModalVisible}
        setLogModalVisible={setLogModalVisible}
        setPinCoords={setPinCoordsProp}
        dropPinCliceked={dropPinClicked}
        setDropPinClicked={setDropPinClicked}
      />
      <LandingPage
        displayLogin={displayLogin}
        pinCoords={pinCoords}
        setDisplayLogin={setDisplayLogin}
        setPinCoords={setPinCoordsProp}
        logModalVisible={logModalVisible}
        setLogModalVisible={setLogModalVisible}
        dropPinClicked={dropPinClicked}
        setDropPinClicked={setDropPinClicked}
      />
      <Footer />
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import authInstance from "./firebase";
import Axios from "axios";
import "./ConfirmDetails.css";
import Logo from "./images/Suzuki-Go-Lockup-x1.png";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

const ConfirmDetails = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordOutline, setPasswordOutline] = useState({});
  const [passwordSame, setPasswordSame] = useState(false);

  const history = useHistory();

  const confirmPasswordOnChange = (e) => {
    setConfirmPassword(e.target.value);

    if (e.target.value != password) {
      setPasswordOutline({ border: "2px solid red" });
      setPasswordSame(false);
    } else {
      setPasswordOutline({ border: "1px solid #d8d8d8" });
      setPasswordSame(true);
    }
  };

  function sendLogin() {
    history.push("/login");
  }

  const submitConfirm = (e) => {
    if (!name) {
      notification["error"]({
        message: "Confirmation error",
        description: "Please enter a name",
      });
      return;
    }

    if (!email) {
      notification["error"]({
        message: "Confirmation error",
        description: "Please enter an email",
      });
      return;
    }

    if (!passwordSame) {
      notification["error"]({
        message: "Confirmation error",
        description: "Passwords do not match",
      });
      return;
    }

    e.preventDefault();
    authInstance
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((registeredUser) => {
        console.log(`NEW USER CREATED: ${registeredUser.user.uid}`);

        Axios.post("https://mysterious-citadel-66074.herokuapp.com/newuser", {
          uid: registeredUser.user.uid,
          votes: [],
        })
          .then(() => {
            console.log("New user added to user db");
          })
          .catch((err) => {
            console.log(err);
          });

        var user = authInstance.auth().currentUser;

        user
          .updateProfile({
            displayName: name,
            photoURL: "/images/avatar.svg",
          })
          .then(function () {
            console.log("User profile set:");
            console.log(`Username: ${name}`);
            window.location.href = "/";
          })
          .catch(function (error) {
            console.log("Error updating user profile");
          });

        console.log("User created");
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == "auth/weak-password") {
          // alert.error("Password too weak.");
          notification["error"]({
            message: "Conformation error",
            description: "Password too weak.",
          });
        } else if (errorCode == "auth/email-already-in-use") {
          window.location.href = "/";
        } else {
          // alert.error(errorMessage);
          notification["error"]({
            message: "Confirmation error",
            description: errorCode + errorMessage,
          });
        }
      });
  };

  return (
    <div className="confirm-details">
      <div className="confirm-details-wrapper form-wrapper">
        <h4 className="login-header">Thank you for registering</h4>
        <h2 className="confirm-details-header">Confirm your details below:</h2>
        <img src={Logo} />
        <input
          type="text"
          placeholder="Name"
          className="confirm-details-name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Email"
          className="confirm-details-email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          className="confirm-details-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Confirm password"
          style={passwordOutline}
          className="confirm-details-confirm-password"
          value={confirmPassword}
          onChange={confirmPasswordOnChange}
        />
        <button
          className="confirm-details-submit-btn submit-btn"
          onClick={submitConfirm}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmDetails;
